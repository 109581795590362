import 'core-js';
import 'react-hot-loader';

import { ThemeProvider, ApiClient, ApiClientContext, AntreaTokenProvider } from '@hyperfish/fishfood';
import Radium from 'radium';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Cookies from 'universal-cookie';

import { FishFail } from './components/FishFail';
import { ACCESS_TOKEN } from './config';
import createStore from './redux/create';
import reducer from './redux/modules/reducer';
import Root from './Root';

const cookie = new Cookies();

const backend = cookie.get('customBackend') || cookie.get('backend');
const tokenProvider = new AntreaTokenProvider(ACCESS_TOKEN);
const client = new ApiClient({ backend, tokenProvider });

const dest = document.getElementById('root');
const store = createStore({ client, reducer, data: window['__data'] });

ReactDOM.render(
  <Radium.StyleRoot>
    <Provider store={store} key="provider">
      <ApiClientContext.Provider value={client}>
        <ThemeProvider theme={{ getFontSize: size => `${size / 16}em` }}>
          <FishFail>
            <Root store={store} />
          </FishFail>
        </ThemeProvider>
      </ApiClientContext.Provider>
    </Provider>
  </Radium.StyleRoot>,
  dest,
);

// enable debugger
if (process.env.NODE_ENV !== 'production') {
  window['react'] = React;
}
