import { GlobalState } from '../../../models/client/';
import mapArrayToObjectById from '../../../utils/mapArrayToObject';
import { CreateIntentFlowSuccess, ResourcesAction, SaveIntentFlowSuccess } from './actions';
import * as types from './types';
import { ApiClient } from '@hyperfish/fishfood';

type State = GlobalState['resources'];

type IntentFlow = State['intentFlowById']['foo'];
type Category = State['categoriesById']['foo'];

// Util Functions
function applyIntentFlowToIntents(state: State, action: CreateIntentFlowSuccess | SaveIntentFlowSuccess): State {
  const id = action.result.intent.id;
  const intents = state.intents;
  const intentsRelatedEntities = state.intentsRelatedEntities;
  let index;
  for (let i = 0; i < intents.length; i++) {
    if (intents[i].id === id) {
      index = i;
      break;
    }
  }

  if (index != null) {
    intents[index] = action.result.intent;
  } else {
    intents.push(action.result.intent);
  }

  if (action.result.relatedEntities != null) {
    const category = action.result.relatedEntities.category;
    if (category != null) {
      intentsRelatedEntities.categories[category.id] = category;
    }
    const owner = action.result.relatedEntities.owner;
    if (owner != null) {
      intentsRelatedEntities[owner.entityId] = owner;
    }
  }

  return {
    ...state,
    intents,
    intentsRelatedEntities,
  };
}

const initialState = {
  intentFlowById: {},
  intentFlowByIdLoading: {},
  intentFlowByIdError: {},
  intentFlowByIdSaving: {},
  intentFlowByIdSavingError: {},
  intentFlowByIdDeleting: {},
  intentFlowByIdDeletingError: {},

  categoryIds: [],
  categoriesById: {},
  categoryByIdSaving: {},
  categoryByIdDeleting: {},
  categoryByIdDeletingError: {},
} as State;

export default function reducer(state: State = initialState, action: ResourcesAction): State {
  switch (action.type) {
    case types.LOAD_INTENTS:
      return {
        ...state,
        intentsLoading: true,
        intentsError: null,
      };
    case types.LOAD_INTENTS_SUCCESS:
      return {
        ...state,
        intentsLoading: false,
        intents: action.result.intents,
        intentsRelatedEntities: action.result.relatedEntities,
      };
    case types.LOAD_INTENTS_FAIL:
      return {
        ...state,
        intentsLoading: false,
        intentsError: action.error,
      };

    case types.LOAD_INTENT_FLOW:
      return {
        ...state,
        intentFlowByIdError: { ...state.intentFlowByIdError, [action.id]: null },
        intentFlowByIdLoading: { ...state.intentFlowByIdLoading, [action.id]: true },
      };
    case types.LOAD_INTENT_FLOW_SUCCESS:
      return {
        ...state,
        intentFlowByIdLoading: { ...state.intentFlowByIdLoading, [action.id]: false },
        intentFlowById: { ...state.intentFlowById, [action.id]: action.result },
      };
    case types.LOAD_INTENT_FLOW_FAIL:
      return {
        ...state,
        intentFlowByIdLoading: { ...state.intentFlowByIdLoading, [action.id]: false },
        intentFlowByIdError: { ...state.intentFlowByIdError, [action.id]: action.error },
      };

    case types.CREATE_INTENT_FLOW:
      return {
        ...state,
        intentFlowCreating: true,
        intentFlowCreatingError: null,
      };
    case types.CREATE_INTENT_FLOW_SUCCESS:
      return {
        ...applyIntentFlowToIntents(state, action),
        intentFlowCreating: false,
        intentFlowById: { ...state.intentFlowById, [action.result.intent.id]: action.result },
      };
    case types.CREATE_INTENT_FLOW_FAIL:
      return {
        ...state,
        intentFlowCreating: false,
        intentFlowCreatingError: action.error,
      };
    case types.CREATE_INTENT_FLOW_FAIL_CLEAR:
      return {
        ...state,
        intentFlowCreatingError: null,
      };

    case types.SAVE_INTENT_FLOW:
      return {
        ...state,
        intentFlowByIdSaving: { ...state.intentFlowByIdSaving, [action.id]: true },
        intentFlowByIdError: { ...state.intentFlowByIdError, [action.id]: null },
      };
    case types.SAVE_INTENT_FLOW_SUCCESS:
      return {
        ...applyIntentFlowToIntents(state, action),
        intentFlowByIdSaving: { ...state.intentFlowByIdSaving, [action.id]: false },
        intentFlowById: { ...state.intentFlowById, [action.id]: action.result },
      };
    case types.SAVE_INTENT_FLOW_FAIL:
      return {
        ...state,
        intentFlowByIdSaving: { ...state.intentFlowByIdSaving, [action.id]: false },
        intentFlowByIdSavingError: { ...state.intentFlowByIdSavingError, [action.id]: action.error },
      };
    case types.SAVE_INTENT_FLOW_FAIL_CLEAR:
      return {
        ...state,
        intentFlowByIdSavingError: { ...state.intentFlowByIdSavingError, [action.id]: null },
      };

    case types.DELETE_INTENT_FLOW:
      return {
        ...state,
        intentFlowByIdDeleting: { ...state.intentFlowByIdDeleting, [action.id]: true },
        intentFlowByIdDeletingError: { ...state.intentFlowByIdDeletingError, [action.id]: null },
      };
    case types.DELETE_INTENT_FLOW_SUCCESS:
      return {
        ...state,
        intentFlowByIdDeleting: { ...state.intentFlowByIdDeleting, [action.id]: false },
        intentFlowById: { ...state.intentFlowById, [action.id]: null },
        intents: state.intents.filter(i => i.id !== action.id),
      };
    case types.DELETE_INTENT_FLOW_FAIL:
      return {
        ...state,
        intentFlowByIdDeleting: { ...state.intentFlowByIdDeleting, [action.id]: false },
        intentFlowByIdDeletingError: { ...state.intentFlowByIdDeletingError, [action.id]: action.error },
      };
    case types.DELETE_INTENT_FLOW_FAIL_CLEAR:
      return {
        ...state,
        intentFlowByIdDeletingError: { ...state.intentFlowByIdDeletingError, [action.id]: null },
      };

    case types.LOAD_CATEGORIES:
      return {
        ...state,
        categoriesLoading: true,
        categoriesError: null,
      };
    case types.LOAD_CATEGORIES_SUCCESS:
      return {
        ...state,
        categoriesLoading: false,
        categoryIds: action.result.categories.map(c => c.id),
        categoriesById: mapArrayToObjectById(action.result.categories),
        categoriesRelatedEntities: action.result.relatedEntities,
      };
    case types.LOAD_CATEGORIES_FAIL:
      return {
        ...state,
        categoriesLoading: false,
        categoriesError: action.error,
      };

    case types.CREATE_CATEGORY:
      return {
        ...state,
        categoryCreating: true,
        categoryCreatingError: null,
      };
    case types.CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        categoryCreating: false,
        categoryIds: [...state.categoryIds, action.result.category.id],
        categoriesById: { ...state.categoriesById, [action.result.category.id]: action.result.category },
        categoriesRelatedEntities: {
          ...state.categoriesRelatedEntities,
          owners: {
            ...state.categoriesRelatedEntities.owners,
            [action.result.relatedEntities.owner.entityId]: action.result.relatedEntities.owner,
          },
        },
      };
    case types.CREATE_CATEGORY_FAIL:
      return {
        ...state,
        categoryCreating: false,
        categoryCreatingError: action.error,
      };
    case types.CREATE_CATEGORY_FAIL_CLEAR:
      return {
        ...state,
        categoryCreatingError: null,
      };

    case types.SAVE_CATEGORY:
      return {
        ...state,
        categoryByIdSaving: { ...state.categoryByIdSaving, [action.id]: true },
        categoryByIdError: { ...state.categoryByIdError, [action.id]: null },
      };
    case types.SAVE_CATEGORY_SUCCESS:
      return {
        ...state,
        categoryByIdSaving: { ...state.categoryByIdSaving, [action.id]: false },
        categoriesById: { ...state.categoriesById, [action.result.category.id]: action.result.category },
        categoriesRelatedEntities: {
          ...state.categoriesRelatedEntities,
          owners: {
            ...state.categoriesRelatedEntities.owners,
            [action.result.relatedEntities.owner.entityId]: action.result.relatedEntities.owner,
          },
        },
      };
    case types.SAVE_CATEGORY_FAIL:
      return {
        ...state,
        categoryByIdSaving: { ...state.categoryByIdSaving, [action.id]: false },
        categoryByIdError: { ...state.categoryByIdError, [action.id]: action.error },
      };
    case types.SAVE_CATEGORY_FAIL_CLEAR:
      return {
        ...state,
        categoryByIdError: { ...state.categoryByIdError, [action.id]: null },
      };

    case types.DELETE_CATEGORY:
      return {
        ...state,
        categoryByIdDeleting: { ...state.categoryByIdDeleting, [action.id]: true },
        categoryByIdDeletingError: { ...state.categoryByIdDeletingError, [action.id]: null },
      };
    case types.DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        categoryByIdDeleting: { ...state.categoryByIdDeleting, [action.id]: false },
        categoryIds: state.categoryIds.filter(id => id !== action.id),
        categoriesById: { ...state.categoriesById, [action.id]: null },
      };
    case types.DELETE_CATEGORY_FAIL:
      return {
        ...state,
        categoryByIdDeleting: { ...state.categoryByIdDeleting, [action.id]: false },
        categoryByIdDeletingError: { ...state.categoryByIdDeletingError, [action.id]: action.error },
      };
    case types.DELETE_CATEGORY_FAIL_CLEAR:
      return {
        ...state,
        categoryByIdDeletingError: { ...state.categoryByIdDeletingError, [action.id]: null },
      };

    default:
      return state;
  }
}

// Action Creators
export function loadIntents() {
  return {
    types: [types.LOAD_INTENTS, types.LOAD_INTENTS_SUCCESS, types.LOAD_INTENTS_FAIL],
    promise: (client: ApiClient) => client.get('/bot/intents'),
    notifications: {
      fail: 'Failed to load employee resources',
    },
  };
}

export function loadIntentFlow(id: string) {
  return {
    types: [types.LOAD_INTENT_FLOW, types.LOAD_INTENT_FLOW_SUCCESS, types.LOAD_INTENT_FLOW_FAIL],
    promise: (client: ApiClient) => client.get(`/bot/intentflow/${id}`),
    id,
    notifications: {
      fail: 'Failed to load employee resource details',
    },
  };
}

export const createIntentFlowClearError = () => ({ type: types.CREATE_INTENT_FLOW_FAIL_CLEAR });

export function createIntentFlow({
  intent,
  responses,
}: {
  intent: Partial<IntentFlow['intent']>;
  responses: Partial<IntentFlow['responses'][0]>[];
}) {
  return {
    types: [types.CREATE_INTENT_FLOW, types.CREATE_INTENT_FLOW_SUCCESS, types.CREATE_INTENT_FLOW_FAIL],
    promise: (client: ApiClient) => client.post(`/bot/intentflow`, { data: { intent, responses } }),
    notifications: {
      fail: 'Failed to create employee resource',
      success: 'Employee resource created successfully',
    },
  };
}

export const saveIntentFlowClearError = (id: string) => ({ type: types.SAVE_INTENT_FLOW_FAIL_CLEAR, id });

export function saveIntentFlow({
  intent,
  responses,
}: {
  intent: Partial<IntentFlow['intent']>;
  responses: Partial<IntentFlow['responses'][0]>[];
}) {
  return {
    types: [types.SAVE_INTENT_FLOW, types.SAVE_INTENT_FLOW_SUCCESS, types.SAVE_INTENT_FLOW_FAIL],
    promise: (client: ApiClient) => client.patch(`/bot/intentflow/${intent.id}`, { data: { intent, responses } }),
    id: intent.id,
    notifications: {
      fail: 'Failed to update employee resource',
      success: 'Employee resource updated successfully',
    },
  };
}

export const deleteIntentFlowClearError = (id: string) => ({ type: types.DELETE_INTENT_FLOW_FAIL_CLEAR, id });

export function deleteIntentFlow(id: string) {
  return {
    types: [types.DELETE_INTENT_FLOW, types.DELETE_INTENT_FLOW_SUCCESS, types.DELETE_INTENT_FLOW_FAIL],
    promise: (client: ApiClient) => client.del(`/bot/intents/${id}`),
    id,
    notifications: {
      fail: 'Failed to delete employee resource',
      success: 'Employee resource deleted successfully',
    },
  };
}

export function loadCategories() {
  return {
    types: [types.LOAD_CATEGORIES, types.LOAD_CATEGORIES_SUCCESS, types.LOAD_CATEGORIES_FAIL],
    promise: (client: ApiClient) => client.get('/bot/categories'),
    notifications: {
      fail: 'Failed to load employee resource categories',
    },
  };
}

export const createCategoryClearError = () => ({ type: types.CREATE_CATEGORY_FAIL_CLEAR });

export function createCategory(data: Category) {
  return {
    types: [types.CREATE_CATEGORY, types.CREATE_CATEGORY_SUCCESS, types.CREATE_CATEGORY_FAIL],
    promise: (client: ApiClient) => client.post(`/bot/categories`, { data }),
    notifications: {
      fail: 'Failed to create employee resource category',
      success: 'Employee resource category created successfully',
    },
  };
}

export const saveCategoryClearError = (id: string) => ({ type: types.SAVE_CATEGORY_FAIL_CLEAR, id });

export function saveCategory(data: Category) {
  return {
    types: [types.SAVE_CATEGORY, types.SAVE_CATEGORY_SUCCESS, types.SAVE_CATEGORY_FAIL],
    promise: (client: ApiClient) => client.patch(`/bot/categories/${data.id}`, { data }),
    id: data.id,
    notifications: {
      fail: 'Failed to update employee resource category',
      success: 'Employee resource category updated successfully',
    },
  };
}

export const deleteCategoryClearError = (id: string) => ({ type: types.DELETE_CATEGORY_FAIL_CLEAR, id });

export function deleteCategory(id: string) {
  return {
    types: [types.DELETE_CATEGORY, types.DELETE_CATEGORY_SUCCESS, types.DELETE_CATEGORY_FAIL],
    promise: (client: ApiClient) => client.del(`/bot/categories/${id}`),
    id,
    notifications: {
      fail: 'Failed to delete employee resource category',
      success: 'Employee resource category deleted successfully',
    },
  };
}
