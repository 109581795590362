export const LOAD_INTENTS = 'antrea/resources/LOAD_INTENTS';
export const LOAD_INTENTS_SUCCESS = 'antrea/resources/LOAD_INTENTS_SUCCESS';
export const LOAD_INTENTS_FAIL = 'antrea/resources/LOAD_INTENTS_FAIL';

export const LOAD_INTENT_FLOW = 'antrea/resources/LOAD_INTENT_FLOW';
export const LOAD_INTENT_FLOW_SUCCESS = 'antrea/resources/LOAD_INTENT_FLOW_SUCCESS';
export const LOAD_INTENT_FLOW_FAIL = 'antrea/resources/LOAD_INTENT_FLOW_FAIL';

export const SAVE_INTENT_FLOW = 'antrea/resources/SAVE_INTENT_FLOW';
export const SAVE_INTENT_FLOW_SUCCESS = 'antrea/resources/SAVE_INTENT_FLOW_SUCCESS';
export const SAVE_INTENT_FLOW_FAIL = 'antrea/resources/SAVE_INTENT_FLOW_FAIL';
export const SAVE_INTENT_FLOW_FAIL_CLEAR = 'antrea/resources/SAVE_INTENT_FLOW_FAIL_CLEAR';

export const CREATE_INTENT_FLOW = 'antrea/resources/CREATE_INTENT_FLOW';
export const CREATE_INTENT_FLOW_SUCCESS = 'antrea/resources/CREATE_INTENT_FLOW_SUCCESS';
export const CREATE_INTENT_FLOW_FAIL = 'antrea/resources/CREATE_INTENT_FLOW_FAIL';
export const CREATE_INTENT_FLOW_FAIL_CLEAR = 'antrea/resources/CREATE_INTENT_FLOW_FAIL_CLEAR';

export const DELETE_INTENT_FLOW = 'antrea/resources/DELETE_INTENT_FLOW';
export const DELETE_INTENT_FLOW_SUCCESS = 'antrea/resources/DELETE_INTENT_FLOW_SUCCESS';
export const DELETE_INTENT_FLOW_FAIL = 'antrea/resources/DELETE_INTENT_FLOW_FAIL';
export const DELETE_INTENT_FLOW_FAIL_CLEAR = 'antrea/resources/DELETE_INTENT_FLOW_FAIL_CLEAR';

export const LOAD_CATEGORIES = 'antrea/resources/LOAD_CATEGORIES';
export const LOAD_CATEGORIES_SUCCESS = 'antrea/resources/LOAD_CATEGORIES_SUCCESS';
export const LOAD_CATEGORIES_FAIL = 'antrea/resources/LOAD_CATEGORIES_FAIL';

export const SAVE_CATEGORY = 'antrea/resources/SAVE_CATEGORY';
export const SAVE_CATEGORY_SUCCESS = 'antrea/resources/SAVE_CATEGORY_SUCCESS';
export const SAVE_CATEGORY_FAIL = 'antrea/resources/SAVE_CATEGORY_FAIL';
export const SAVE_CATEGORY_FAIL_CLEAR = 'antrea/resources/SAVE_CATEGORY_FAIL_CLEAR';

export const CREATE_CATEGORY = 'antrea/resources/CREATE_CATEGORY';
export const CREATE_CATEGORY_SUCCESS = 'antrea/resources/CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_FAIL = 'antrea/resources/CREATE_CATEGORY_FAIL';
export const CREATE_CATEGORY_FAIL_CLEAR = 'antrea/resources/CREATE_CATEGORY_FAIL_CLEAR';

export const DELETE_CATEGORY = 'antrea/resources/DELETE_CATEGORY';
export const DELETE_CATEGORY_SUCCESS = 'antrea/resources/DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAIL = 'antrea/resources/DELETE_CATEGORY_FAIL';
export const DELETE_CATEGORY_FAIL_CLEAR = 'antrea/resources/DELETE_CATEGORY_FAIL_CLEAR';
