import { Button, ButtonContainer, LoadingSplash, getFrom } from '@hyperfish/fishfood';
import cx from 'classnames';
import React from 'react';
import { connect } from 'react-redux';

import { HyperForm } from '../../components/';
import { OrgSettings, OrgSettingsKeys } from '../../models/api';
import { GlobalState } from '../../models/client/';
import { isFree } from '../../redux/modules/auth';
import { cancel as cancelBranding, save as saveBranding } from '../../redux/modules/branding';
import {
  editStart as editCollectionStart,
  load as loadCollections,
  save as saveCollection,
} from '../../redux/modules/collections';
import { clear as clearUsers, load as loadUsers } from '../../redux/modules/externalUsers';
import { load as loadFieldSchema } from '../../redux/modules/fieldSchema';
import { createNotification } from '../../redux/modules/notifications';
import { loadCurrent, hasOrgFeature } from '../../redux/modules/orgs';
import {
  cancel as cancelPermissions,
  edit as editPermissions,
  getDirty as getDirtyPermissions,
  load as loadPermissions,
  save as savePermissions,
} from '../../redux/modules/permissions';
import { show as showPremiumModal } from '../../redux/modules/premiumModals';
import {
  cancel,
  edit as update,
  load as loadSettings,
  save,
  setAuditFilterTestClicked,
  testAuditFilters,
} from '../../redux/modules/settings';
import classes from './styles.module.scss';
import { parse } from 'query-string';
import { Switch, Redirect, Route } from 'react-router-dom';
import {
  SettingsGeneral,
  SettingsApproval,
  SettingsAttributes,
  SettingsBranding,
  SettingsHyperbot,
  SettingsEmbed,
  SettingsOrgDirectory,
  SettingsRelationship,
} from '../../containers';
import {
  ORG_FEATURE_MODE_ANALYZE,
  ORG_FEATURE_MODE_PILOT,
  ORG_FEATURE_MODE_RUN,
  ORG_FEATURE_PROFILE_VALIDATION,
  ORG_FEATURE_DIRECTORY_WEB_PARTS,
  ORG_FEATURE_READ_ONLY_MODE_SWITCH,
} from '../../config';

type SettingsKeysArray = (keyof OrgSettings | 'attributes_approval_manualApproval_*')[];

const connector = connect(
  state => ({
    users: state.externalUsers.data,
    usersLoading: state.externalUsers.loading,
    usersById: state.externalUsers.dataById,
    brandingDirty: state.branding.isDirty,
    brandingLoading: state.branding.loading,
    brandingSaving: state.branding.saving,
    cleanSettings: state.settings.data,
    collectionDirty: state.collections.collectionDirty,
    collections: state.collections.dataIds.map(id => state.collections.dataById[id]),
    collectionSaving: state.collections.saving,
    collectionsById: state.collections.dataById,
    currentOrg: state.orgs.current,
    currentOrgLoading: state.orgs.loadingCurrent,
    disconnected: !state.orgs.currentConnected,
    dirtyBranding: state.branding.dirtyData,
    dirtyCollection: state.collections.dirtyCollection,
    dirtySettings: state.settings.dirtyData,
    fieldSchema: state.fieldSchema.data,
    fieldSchemaLoading: state.fieldSchema.loading,
    isFree: isFree(state),
    permissionsByAudience: state.permissions.dataByAudience,
    permissionsByAudienceError: state.permissions.errorByAudience,
    permissionsByAudienceLoading: state.permissions.loadingByAudience,
    permissionsByAudienceSaving: state.permissions.savingByAudience,
    permissionsByAudienceDirty: state.permissions.dirtyByAudience,
    permissionsByAudienceDirtyArray: getDirtyPermissions(state),
    relatedEntities: state.settings.relatedEntities,
    saving: state.settings.saving,
    settingsDirty: state.settings.dirty,
    settingsLoading: state.settings.loading,
    licenseAnalyze: hasOrgFeature(state, ORG_FEATURE_MODE_ANALYZE),
    licensePilot: hasOrgFeature(state, ORG_FEATURE_MODE_PILOT),
    licenseRun: hasOrgFeature(state, ORG_FEATURE_MODE_RUN),
    licenseValidation: hasOrgFeature(state, ORG_FEATURE_PROFILE_VALIDATION),
    licenseWebparts: hasOrgFeature(state, ORG_FEATURE_DIRECTORY_WEB_PARTS),
    licenseReadOnlyModeSwitch: hasOrgFeature(state, ORG_FEATURE_READ_ONLY_MODE_SWITCH),
    auditFiltersTestLoading: state.settings.auditFilterTestLoading,
    auditFilterTestResult: state.settings.auditFilterTestResult,
    auditFilterTestClicked: state.settings.auditFilterTestClicked,
  }),
  {
    cancel,
    cancelBranding,
    cancelPermissions,
    clearUsers,
    createNotification,
    editCollectionStart,
    editPermissions,
    loadCollections,
    loadCurrent,
    loadFieldSchema,
    loadPermissions,
    loadSettings,
    loadUsers,
    save,
    saveBranding,
    saveCollection,
    savePermissions,
    showPremiumModal,
    update,
    testAuditFilters,
    setAuditFilterTestClicked,
  },
);

type Props = typeof connector['props'];
export type SettingsProps = Props & {
  currentAudienceId: string;
  isMaster: boolean;
  loadScopedSettings: (settingKeys: SettingsKeysArray) => void;
  settings: GlobalState['settings']['data']['foo'];
  handleCancel: SettingsLayoutComponent['handleCancel'];
  handleFormMount: HyperForm['props']['onMount'];
  handleFormUnmount: HyperForm['props']['onUnmount'];
  handleFormInvalidChange: HyperForm['props']['onInvalidChange'];
  additionalChildren?: JSX.Element[];
};

export class SettingsLayoutComponent extends React.Component<SettingsProps, {}> {
  forms: HyperForm[] = [];
  currentSettingsKeys: SettingsKeysArray;
  mapOverrideToKey = {
    [OrgSettingsKeys.inheritance.autoApprove]: [OrgSettingsKeys.attributes.approval.manualApprovalWildcard],
  };

  private getAudienceIdFromProps(props: Props): string {
    const query = parse(props.location.search);
    return (query.audienceId as string) || (props.currentOrg && props.currentOrg.id);
  }

  get currentAudienceId(): string {
    return this.getAudienceIdFromProps(this.props);
  }

  componentDidMount() {
    const {
      fieldSchemaLoading,
      loadCollections,
      loadFieldSchema,
      loadPermissions,
      permissionsByAudienceLoading,
      currentOrg,
    } = this.props;

    if (!fieldSchemaLoading) {
      loadFieldSchema();
    }
    if (!permissionsByAudienceLoading[this.currentAudienceId]) {
      loadPermissions(this.currentAudienceId);
    }
    if (currentOrg && this.currentAudienceId !== currentOrg.id) {
      loadPermissions(currentOrg.id);
    }
    loadCollections();
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      createNotification,
      dirtyCollection,
      editCollectionStart,
      location,
      collectionsById,
      loadPermissions,
    } = this.props;
    const audienceId = parse(location.search).audienceId as string;

    if (
      audienceId &&
      nextProps.collectionsById[audienceId] &&
      (!dirtyCollection || dirtyCollection.id !== audienceId)
    ) {
      // loadAllScopes();
      editCollectionStart(nextProps.collectionsById[audienceId] as any);
    }

    if (this.currentAudienceId !== this.getAudienceIdFromProps(nextProps)) {
      // Collection changed
      this.handleCancel();
      if (this.currentSettingsKeys) {
        this.handleLoadSettings(this.currentSettingsKeys, this.getAudienceIdFromProps(nextProps));
      }

      loadPermissions(this.getAudienceIdFromProps(nextProps));
      if (nextProps.currentOrg && nextProps.currentOrg.id !== this.getAudienceIdFromProps(nextProps)) {
        loadPermissions(nextProps.currentOrg.id);
      }

      // isMaster:
      // !ownProps.location.query.audienceId || ownProps.location.query.audienceId === (state.orgs.current.id || {})['id'],
      const nextAudience = parse(nextProps.location.search).audienceId as string;
      const nextMaster = !nextAudience == null || nextAudience === getFrom(nextProps.currentOrg)('id').value;

      if (nextMaster || collectionsById[this.getAudienceIdFromProps(nextProps)]) {
        createNotification({
          title: 'Selected Collection Changed',
          message: `Now editing settings for collection: ${
            nextMaster ? 'Master' : collectionsById[this.getAudienceIdFromProps(nextProps)].definition['name']
          }`,
        });
      }
    }
  }

  getMergedSettings() {
    const { cleanSettings, currentOrg, dirtySettings, relatedEntities } = this.props;

    if (!currentOrg || !cleanSettings || !dirtySettings) {
      return;
    }
    if (this.currentAudienceId === currentOrg.id) {
      // Viewing master
      const cleanOrgSettings = cleanSettings[currentOrg.id] || ({} as Props['cleanSettings']['foo']);
      const dirtyOrgSettings = dirtySettings[currentOrg.id] || ({} as Props['dirtySettings']['foo']);

      const mergedSettings = {
        ...cleanOrgSettings,
        ...dirtyOrgSettings,
      };

      return mergedSettings;
    }

    const cleanAudienceSettings = cleanSettings[this.currentAudienceId] || ({} as Props['dirtySettings']['foo']);
    const cleanMasterSettings = cleanSettings[currentOrg.id] || ({} as Props['dirtySettings']['foo']);
    const dirtyAudienceSettings = dirtySettings[this.currentAudienceId] || ({} as Props['dirtySettings']['foo']);

    // We are looking at a non-master audience. Time for fancy merging.
    const mergedAudienceSettings = {
      ...cleanAudienceSettings,
      ...dirtyAudienceSettings,
      [OrgSettingsKeys.inheritance.autoApprove]:
        dirtyAudienceSettings.inheritance_autoApprove != null
          ? dirtyAudienceSettings.inheritance_autoApprove
          : cleanAudienceSettings.inheritance_autoApprove,
      [OrgSettingsKeys.inheritance.approverPermissions]:
        dirtyAudienceSettings.inheritance_approverPermissions != null
          ? dirtyAudienceSettings.inheritance_approverPermissions
          : cleanAudienceSettings.inheritance_approverPermissions,
      [OrgSettingsKeys.broadcast.enabled]: cleanMasterSettings.broadcast_enabled,
      [OrgSettingsKeys.broadcast.tone]: (dirtyAudienceSettings.inheritance_broadcast_tone != null
      ? dirtyAudienceSettings.inheritance_broadcast_tone
      : cleanAudienceSettings.inheritance_broadcast_tone)
        ? cleanMasterSettings.broadcast_tone
        : dirtyAudienceSettings.broadcast_tone || cleanAudienceSettings.broadcast_tone,
    };

    if (
      relatedEntities.audienceSpecific[this.currentAudienceId] &&
      relatedEntities.audienceSpecific[this.currentAudienceId].fields
    ) {
      Object.keys(relatedEntities.audienceSpecific[this.currentAudienceId].fields).forEach(fieldId => {
        const key = OrgSettingsKeys.attributes.approval.manualApprovalWildcard.replace('*', fieldId);
        mergedAudienceSettings[key] = mergedAudienceSettings.inheritance_autoApprove
          ? cleanMasterSettings[key]
          : mergedAudienceSettings[key];
      });
    }

    return mergedAudienceSettings;
  }

  handleSave = () => {
    const {
      brandingDirty,
      collectionDirty,
      dirtyBranding,
      dirtyCollection,
      dirtySettings,
      permissionsByAudienceDirtyArray,
      save,
      saveBranding,
      saveCollection,
      savePermissions,
      settingsDirty,
      auditFilterTestResult,
      auditFilterTestClicked,
    } = this.props;

    if (settingsDirty) {
      const dirtySettingsToSave = { ...dirtySettings[this.currentAudienceId] };

      const enabled = getFrom(dirtySettingsToSave)('audit_filters')('enabled').defaultTo(false);
      const clicked = !!auditFilterTestClicked;
      const testSucceeded = getFrom(auditFilterTestResult)('success').defaultTo(false);

      const auditFilterTested = !enabled || (clicked && testSucceeded);

      if (!auditFilterTested) {
        this.props.createNotification({
          title: 'Audit Scope Filter',
          type: 'warning',
          duration: 5,
          message: 'Invalid or untested filter for Audit Scope, please review and click on Test Filter.',
        });
        return;
      }

      // Special handling of audit scopes and exclusions to support UI Radio state
      [OrgSettingsKeys.audit.scopes, OrgSettingsKeys.audit.exclusions].forEach(key => {
        if (dirtySettingsToSave.hasOwnProperty(key) && dirtySettingsToSave[key] === null) {
          dirtySettingsToSave[key] = [];
        }
      });

      save(this.currentAudienceId, dirtySettingsToSave);
    }
    if (brandingDirty) {
      saveBranding(this.currentAudienceId, dirtyBranding.audiences[this.currentAudienceId]);
    }
    if (collectionDirty) {
      saveCollection(dirtyCollection);
    }
    if (!!permissionsByAudienceDirtyArray) {
      permissionsByAudienceDirtyArray.forEach(p => savePermissions(p));
    }
  };

  handleCancel = () => {
    const {
      brandingDirty,
      cancel,
      cancelBranding,
      cancelPermissions,
      collectionDirty,
      collectionsById,
      editCollectionStart,
      permissionsByAudienceDirtyArray,
      settingsDirty,
    } = this.props;

    if (settingsDirty) {
      cancel();
    }
    if (brandingDirty) {
      cancelBranding();
    }
    if (collectionDirty) {
      editCollectionStart(collectionsById[this.currentAudienceId] as any);
    }
    if (!!permissionsByAudienceDirtyArray) {
      cancelPermissions();
    }
  };

  handleFormMount = (form: HyperForm) => {
    this.forms.push(form);
  };

  handleFormUnmount = (form: HyperForm) => {
    const index = this.forms.indexOf(form);
    if (index > -1) {
      this.forms.splice(index, 1);
    }
  };

  handleFormInvalidChange = () => {
    this.forceUpdate();
  };

  handleLoadSettings = (settingsKeys: SettingsKeysArray, currentAudienceId = this.currentAudienceId) => {
    const { currentOrg } = this.props;

    this.currentSettingsKeys = settingsKeys;

    this.props.loadSettings(
      currentAudienceId === currentOrg.id ? [currentAudienceId] : [currentOrg.id, currentAudienceId],
      settingsKeys,
    );
  };

  render() {
    const childProps = {
      ...this.props,
      isMaster: this.currentAudienceId === getFrom(this.props.currentOrg)('id').value,
      currentAudienceId: this.currentAudienceId,
      handleCancel: this.handleCancel,
      handleFormInvalidChange: this.handleFormInvalidChange,
      handleFormMount: this.handleFormMount,
      handleFormUnmount: this.handleFormUnmount,
      settings: this.getMergedSettings(),
      loadScopedSettings: this.handleLoadSettings,
    } as any;

    return (
      <div className="layout-settings clearfix">
        {this.renderButtons(true)}
        {!this.props.currentOrg ? (
          <LoadingSplash />
        ) : (
          <Switch>
            <Redirect exact from="/settings" to="/settings/general" />
            <Route path="/settings/general" render={() => <SettingsGeneral {...childProps} />} />
            <Route path="/settings/approval" render={() => <SettingsApproval {...childProps} />} />
            <Route path="/settings/attributes" render={() => <SettingsAttributes {...childProps} />} />
            <Route path="/settings/branding" render={() => <SettingsBranding {...childProps} />} />
            <Route path="/settings/hyperbot" render={() => <SettingsHyperbot {...childProps} />} />
            <Route path="/settings/embed" render={() => <SettingsEmbed {...childProps} />} />
            <Route path="/settings/orgdirectory" render={() => <SettingsOrgDirectory {...childProps} />} />
            <Route path="/settings/relationships" render={() => <SettingsRelationship {...childProps} />} />
          </Switch>
        )}
        {this.renderButtons()}
      </div>
    );
  }

  renderButtons(top?: boolean) {
    const {
      brandingDirty,
      brandingLoading,
      brandingSaving,
      collectionDirty,
      collectionSaving,
      location: { pathname },
      saving,
      settingsDirty,
      settingsLoading,
      permissionsByAudienceLoading,
      permissionsByAudienceDirtyArray,
      permissionsByAudienceSaving,
    } = this.props;

    const showButtons =
      [
        '/settings/clients',
        '/settings/directory',
        '/settings/attributes',
        '/settings/embed',
        '/settings/orgdirectory',
        '/settings/relationships',
      ].filter(path => pathname.indexOf(path) === 0).length === 0;

    const combinedLoading = settingsLoading || brandingLoading || permissionsByAudienceLoading[this.currentAudienceId];

    if (combinedLoading || !showButtons) {
      return;
    }

    const combinedSaving =
      saving || brandingSaving || collectionSaving || permissionsByAudienceSaving[this.currentAudienceId];
    const combinedDirty = settingsDirty || brandingDirty || collectionDirty || !!permissionsByAudienceDirtyArray;
    const combinedInvalid = this.forms.filter(form => form.invalid).length > 0;

    return (
      <ButtonContainer className={cx(classes.buttonContainer, { [classes.buttonContainer__top]: top })}>
        <Button size="small" onClick={this.handleCancel} disabled={!combinedDirty}>
          Cancel
        </Button>
        <Button
          size="small"
          variant="solid"
          color="primary"
          disabled={combinedSaving || !combinedDirty || combinedInvalid}
          onClick={this.handleSave}
        >
          Save
        </Button>
      </ButtonContainer>
    );
  }
}

export const SettingsLayout = connector(SettingsLayoutComponent);

// React.cloneElement(this.props.children as React.ReactElement<any>, {
//   ...rest,
//   handleCancel: this.handleCancel,
//   handleFormInvalidChange: this.handleFormInvalidChange,
//   handleFormMount: this.handleFormMount,
//   handleFormUnmount: this.handleFormUnmount,
//   settings: this.getMergedSettings(),
//   loadScopedSettings: this.handleLoadSettings,
// })
