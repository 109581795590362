import {
  FiAlertTriangle,
  FiChevronRight,
  FiHome,
  FiCheckSquare,
  FiPackage,
  FiArchive,
  FiUsers,
  FiSettings,
  FiHelpCircle,
  FiUnlock,
} from '@hyperfish/fishfood';
import { parse } from 'query-string';
import { ResponseOuApiAudience } from '@hyperfish/antrea-api-contracts/src/audience';
import Radium from 'radium';
import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter, RouteComponentProps, Switch, Route } from 'react-router-dom';

import { NotificationContainer, PremiumModal, SideNav, UserMenu, NavItem, AuthedRoute } from '../components/';
import { OrgSettingsKeys } from '../models/api';
import { clearBackend, isAdmin, isApprover, isAuthed, isFree } from '../redux/modules/auth';
import { load as loadChanges } from '../redux/modules/changes';
import { dismissNotification } from '../redux/modules/notifications';
import {
  isOnboarded,
  loadCurrent as loadCurrentOrg,
  updateCurrent as updateCurrentOrg,
  hasOrgFeature,
} from '../redux/modules/orgs';
import { hide as hidePremiumModal, show as showPremiumModal } from '../redux/modules/premiumModals';
import { load as loadSelf, loadExternal } from '../redux/modules/self';
import { closeMenu, openMenu } from '../redux/modules/ui';
import StyleUtil from '../utils/StyleUtil';
import {
  Home,
  AdminAudits,
  Approve,
  Users,
  Collections,
  Log,
  Help,
  Upgrade,
  NotFound,
  GettingStarted,
} from '../containers';
import { SettingsLayout } from './SettingsLayout';
import { isBoolean } from 'util';
import { ORG_FEATURE_DIRECTORY_WEB_PARTS } from '../config';
// import DiscontinuationWarning from './DiscontinuationWarning';

const connector = connect(
  state => ({
    activeModal: state.premiumModals.activeModal,
    audiences: state.collections.dataIds.map(id => state.collections.dataById[id]),
    audiencesById: state.collections.dataById,
    changes: state.changes.changes,
    changesById: state.changes.changesById,
    currentOrg: state.orgs.current,
    customBackend: !!state.auth.customBackend,
    disconnected: state.orgs.currentConnected === false,
    externalUsers: state.self.externalUsers,
    isAuthed: isAuthed(state),
    isAdmin: isAdmin(state),
    isApprover: isApprover(state),
    isFree: isFree(state),
    isOnboarded: isOnboarded(state),
    loadingChanges: state.changes.loading,
    loadingCurrentOrg: state.orgs.loadingCurrent,
    loadingExternal: state.self.loadingExternal['current'],
    loadingSelf: state.self.loading,
    menuOpen: state.ui.userMenuOpen,
    notifications: state.notifications.notifications,
    token: state.auth.token,
    user: state.self.user,
    userFeatures: state.auth.userFeatures || [],
    licenseWebparts: hasOrgFeature(state, ORG_FEATURE_DIRECTORY_WEB_PARTS),
  }),
  {
    clearBackend,
    closeMenu,
    dismissNotification,
    hidePremiumModal,
    loadChanges,
    loadCurrentOrg,
    loadExternal,
    loadSelf,
    openMenu,
    showPremiumModal,
    updateCurrentOrg,
  },
);

type Props = typeof connector['props'] & RouteComponentProps;

const styles = StyleUtil({
  directoryContainer: {
    color: '#3a3a3a',
  },
  directoryLabel: {
    borderRight: '1px solid #4a4a4a',
    display: 'inline-block',
    fontSize: 18,
    lineHeight: '30px',
    marginRight: 10,
    paddingRight: 10,
  },
  modeLabel: {
    borderRight: 'none',
    paddingRight: 0,
    marginRight: 4,
  },
  directory: {
    lineHeight: '30px',
  },
  mode: {
    borderRadius: 100,
    color: StyleUtil.colors.text.light,
    display: 'inline-block',
    height: 24,
    padding: '0 16px',
    textTransform: 'uppercase',
    fontSize: 12,
    lineHeight: '24px',
    textAlign: 'center',
  },
  changeMode: {
    cursor: 'pointer',
    fontSize: 12,
    marginLeft: 10,
  },
  userContainer: {
    color: 'rgba(58, 58, 58, 0.8)',
    fontSize: 12,
    position: 'relative',
  },
  userGreeting: {
    marginRight: 6,
    verticalAlign: 'top',
  },
  userPhoto: {
    height: 46,
    marginRight: 6,
    verticalAlign: 'middle',
    width: 'auto',
    borderRadius: '50%',
  },
  userToggle: {
    cursor: 'pointer',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  userToggleChevron: {
    display: 'inline-block',
    height: 0,
    borderBottom: '0px solid transparent',
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderTop: `6px solid ${StyleUtil.colors.blue}`,
    width: 0,
  },
  userMenu: {
    marginTop: -15,
  },
  banner: {
    backgroundColor: '#d0011b',
    color: StyleUtil.colors.text.light,
    display: 'block',
    fontWeight: 700,
    marginLeft: -40,
    marginRight: -40,
    padding: '5px 40px',
    textAlign: 'center',
  },
  bannerText: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginLeft: 8,
  },
  viewWrapper: {
    maxWidth: 960,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

export class AppLayoutC extends React.Component<Props, {}> {
  private dashRoute = '/';
  private signinRoute = '/login';
  private onboardRoute = '/getting-started';

  UNSAFE_componentWillMount() {
    // Not Onboarded
    if (
      this.props.currentOrg &&
      this.props.currentOrg.settings[this.props.currentOrg.id][OrgSettingsKeys.onboarding.step] !== 'complete'
    ) {
      this.props.history.replace(this.onboardRoute);
    }
  }

  componentDidMount() {
    const { isAuthed, loadCurrentOrg, loadSelf, currentOrg } = this.props;

    if (!isAuthed) {
      return;
    }
    loadCurrentOrg();
    loadSelf();
    if (!!currentOrg) {
      this.loadPostOrgStuff();
    }
  }

  loadPostOrgStuff(nextProps?: Props) {
    const { currentOrg, disconnected, isOnboarded, loadChanges, loadExternal, loadingChanges, loadingExternal } =
      nextProps || this.props;

    if (!loadingChanges && isOnboarded) {
      loadChanges({ statuses: 'pending,failed' });
    }
    if (!loadingExternal && (currentOrg.type === 'Online' || !disconnected)) {
      loadExternal();
    }
  }

  shouldComponentUpdate(nextProps: Props) {
    if (!nextProps.isOnboarded && nextProps.location.pathname !== '/getting-started') {
      return false;
    }
    return true;
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    // Logged out
    if (this.props.token && !nextProps.token) {
      this.props.history.replace(this.signinRoute);
      return;
    }
    // Received Org
    if (!this.props.currentOrg && nextProps.currentOrg) {
      this.loadPostOrgStuff(nextProps);
      // Not Onboarded
      if (!nextProps.isOnboarded) {
        this.props.history.replace(this.onboardRoute);
        return;
      }
    }
    // Onboard Status Changed
    if (
      this.props.currentOrg &&
      nextProps.currentOrg &&
      this.props.currentOrg.settings[this.props.currentOrg.id][OrgSettingsKeys.onboarding.step] !==
        nextProps.currentOrg.settings[nextProps.currentOrg.id][OrgSettingsKeys.onboarding.step]
    ) {
      // Onboard Completed
      if (nextProps.currentOrg.settings[nextProps.currentOrg.id][OrgSettingsKeys.onboarding.step] === 'complete') {
        if (!nextProps.loadingExternal && nextProps.currentOrg.type !== 'Online' && !nextProps.disconnected) {
          nextProps.loadExternal();
        }
        this.props.history.replace(this.dashRoute);
        return;
      } else {
        // Onboard reset
        this.props.history.replace(this.onboardRoute);
        return;
      }
    }
  }

  clickHandler = () => {
    this.handleMenuClose();
  };

  handleMenuOpen = () => {
    document.addEventListener('click', this.clickHandler);
    this.props.openMenu();
  };

  handleMenuClose = () => {
    document.removeEventListener('click', this.clickHandler);
    this.props.closeMenu();
  };

  handleAudienceChange = (audienceId: string) => {
    const { history, location } = this.props;

    const audiencePages = [
      '/settings/general',
      '/settings/approval',
      '/settings/attributes',
      '/settings/branding',
      '/settings/hyperbot',
      '/settings/relationships',
    ];

    history.push({
      pathname: audiencePages.indexOf(location.pathname) > -1 ? location.pathname : '/settings/general',
      search: audienceId ? '?audienceId=' + audienceId : '',
    });
  };

  getNavList(): NavItem[] {
    const { isFree, isAdmin, licenseWebparts } = this.props;
    return [
      isAdmin && {
        label: 'Home',
        path: '/home',
        icon: <FiHome />,
      },
      {
        label: 'Approve',
        path: '/approve',
        icon: <FiCheckSquare />,
      },
      isAdmin && {
        label: 'Collections',
        path: '/collections',
        icon: <FiPackage />,
      },
      isAdmin && {
        label: 'Event Log',
        path: '/log',
        icon: <FiArchive />,
      },
      isAdmin && {
        label: 'Users',
        path: '/users',
        icon: <FiUsers />,
      },
      isAdmin && {
        label: 'Settings',
        path: '/settings',
        icon: <FiSettings />,
        showAudiencePicker: true,
        subNav: [
          { label: 'General', path: '/settings/general' },
          { label: 'Approval', path: '/settings/approval' },
          { label: 'Attributes', path: '/settings/attributes' },
          { label: 'Branding', path: '/settings/branding' },
          { label: 'Hyperbot', path: '/settings/hyperbot' },
          { label: 'Relationships', path: '/settings/relationships' },
          licenseWebparts && { label: 'Embed', path: '/settings/embed' },
          licenseWebparts && { label: 'Org Directory', path: '/settings/orgdirectory' },
        ].filter(x => !isBoolean(x)),
      },
      ,
      { label: 'Help', path: '/help', icon: <FiHelpCircle /> },
      isFree && {
        label: 'Upgrade',
        path: '/upgrade',
        icon: <FiUnlock />,
      },
    ].filter(Boolean);
    // const list = [];

    // if (!route || !route.childRoutes) {
    //   return;
    // }

    // for (const r of route.childRoutes) {
    //   if (!r.title) {
    //     continue;
    //   }
    //   const path = `/${r.path.replace(/\(.*/, '')}`;
    //   const notifications: string = path === '/approve' && this.getApproveNotifications();
    //   const label = r.title + (notifications ? ` (${notifications})` : '');
    //   const icon = r.icon;
    //   const hide =
    //     (r.hide && r.hide(location)) ||
    //     (!!r.features && r.features.filter(f => userFeatures.indexOf(f) > -1).length === 0);
    //   const showAudiencePicker = r.showAudiencePicker;
    //   const premiumLock = r.premiumLock && isFree;
    //   let subNav;

    //   if (r.childRoutes) {
    //     subNav = [];

    //     for (const childRoute of r.childRoutes) {
    //       if (!childRoute.title) {
    //         continue;
    //       }
    //       subNav.push({
    //         label: childRoute.title,
    //         path: `${path}/${childRoute.path.replace(/\(.*/, '')}`,
    //         hide: childRoute.hide && childRoute.hide(location),
    //       });
    //     }
    //   }

    //   list.push({
    //     hide,
    //     icon,
    //     label,
    //     path,
    //     showAudiencePicker,
    //     subNav,
    //     premiumLock,
    //   });
    // }

    // return list;
  }

  getEmail() {
    const { loadingExternal, externalUsers, user } = this.props;
    if (loadingExternal) {
      return;
    }
    if (!externalUsers) {
      return user && user.email;
    }
    return externalUsers.profile.mail || (user && user.email);
  }

  getDisplayName() {
    const { loadingExternal, externalUsers, user } = this.props;
    if (loadingExternal) {
      return;
    }
    if (!externalUsers) {
      return user && user.displayName;
    }
    return (
      (externalUsers.profileType === 'msGraph'
        ? externalUsers.profile.displayName
        : externalUsers.profile.displayname) ||
      (user && user.displayName)
    );
  }

  getGreetingName() {
    const { loadingExternal, externalUsers, user } = this.props;

    if (loadingExternal) {
      return;
    }
    if (!externalUsers) {
      return user && user.displayName;
    }
    return (
      (externalUsers.profileType === 'msGraph' ? externalUsers.profile.givenName : externalUsers.profile.givenname) ||
      (user && user.displayName)
    );
  }

  getPhoto() {
    const { loadingExternal, externalUsers } = this.props;

    return (
      !!externalUsers &&
      !loadingExternal &&
      (externalUsers.relatedEntities &&
        externalUsers.relatedEntities.profilePicture &&
        externalUsers.relatedEntities.profilePicture.content &&
        `data:${externalUsers.relatedEntities.profilePicture.mimeType || 'image/jpeg'};base64,${
          externalUsers.relatedEntities.profilePicture.content
        }`)
    );
  }

  getApproveNotifications() {
    const { changes, changesById } = this.props;

    const approveNotifications =
      !!changes && !!changesById
        ? changes.filter(c => changesById[c] && changesById[c].status === 'pending').length
        : null;

    return approveNotifications == null
      ? null
      : approveNotifications > 99
      ? '100+'
      : approveNotifications.toLocaleString();
  }

  render() {
    const {
      audiences,
      currentOrg,
      disconnected,
      dismissNotification,
      isAdmin,
      isApprover,
      isFree,
      isOnboarded,
      location,
      notifications,
      showPremiumModal,
      user,
    } = this.props;
    const query = parse(location.search);

    return (
      <div className="layout-app">
        <SideNav
          activeAudience={query.audienceId as string}
          audiences={audiences && (audiences.filter(a => a.type !== 'Global') as ResponseOuApiAudience[])}
          onAudienceChange={this.handleAudienceChange}
          disabled={!isOnboarded}
          path={location.pathname}
          navList={this.getNavList()}
          showModal={showPremiumModal}
        />
        <main role="main">
          {/* <DiscontinuationWarning /> */}

          {this.renderHeader()}
          {isOnboarded && isAdmin && disconnected && (
            <Link style={styles.banner} to="/settings/general">
              <FiAlertTriangle />
              <span style={styles.bannerText}>
                {currentOrg && currentOrg.type === 'Online'
                  ? 'Directory Authorization Needed'
                  : 'Directory Disconnected'}
              </span>
            </Link>
          )}
          {!!currentOrg && (
            <div style={styles.viewWrapper}>
              {this.renderBreadcrumb()}
              <Switch>
                <AuthedRoute isAllowed={isAdmin || isApprover} path="/getting-started" component={GettingStarted} />
                <AuthedRoute isAllowed={isAdmin} path="/admin/audits" component={AdminAudits} />
                <AuthedRoute isAllowed={isAdmin} path="/home" component={Home} />
                <AuthedRoute isAllowed={isApprover} path="/approve" component={Approve} />
                <AuthedRoute isAllowed={isAdmin} path="/collections" component={Collections} />
                <AuthedRoute isAllowed={isAdmin} path="/log" component={Log} />
                <AuthedRoute isAllowed={isAdmin} path="/users" component={Users} />
                <AuthedRoute isAllowed={isAdmin} path="/settings" component={SettingsLayout} />
                <Route path="/help" component={Help} />
                <Route path="/upgrade" component={Upgrade} />
                <Route component={NotFound} />
              </Switch>
            </div>
          )}
        </main>
        {isOnboarded && user && currentOrg && <OnboardingScript user={user} currentOrg={currentOrg} isFree={isFree} />}
        <PremiumModal hideModal={this.props.hidePremiumModal} activeModal={this.props.activeModal} />
        <NotificationContainer notifications={notifications} dismiss={dismissNotification} />
      </div>
    );
  }

  renderHeader() {
    const { currentOrg, isAdmin, isOnboarded, menuOpen } = this.props;

    const email = this.getEmail();
    const displayName = this.getDisplayName();
    const greetingName = this.getGreetingName();
    const photo = this.getPhoto();

    return (
      <header
        className="header"
        style={{
          height: 64 + 5,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: `5px solid ${
            {
              Pilot: '#f2cb61',
              Analyze: '#F82A2A',
              Run: '#6ae6c6',
            }[currentOrg && currentOrg.settings[currentOrg.id][OrgSettingsKeys.global.mode]]
          }`,
        }}
      >
        <div style={styles.directoryContainer}>
          {/* <span style={styles.directoryLabel}>DIRECTORY</span> */}
          <span style={styles.directory}>{currentOrg && currentOrg.name}</span>
        </div>
        <div style={styles.directoryContainer}>
          <span style={[styles.directoryLabel, styles.modeLabel] as any}>
            <span style={{ verticalAlign: 'middle', marginRight: 4 }}>MODE</span>
            <FiChevronRight />
          </span>
          <span style={styles.directory}>
            <span
              style={
                [
                  styles.mode,
                  {
                    backgroundColor: {
                      Pilot: '#f2cb61',
                      Analyze: '#F82A2A',
                      Run: '#6ae6c6',
                    }[currentOrg && currentOrg.settings[currentOrg.id][OrgSettingsKeys.global.mode]],
                  },
                ] as any
              }
            >
              {currentOrg && currentOrg.settings[currentOrg.id][OrgSettingsKeys.global.mode]}
            </span>
            {isOnboarded && isAdmin && (
              <Link to="/settings/general" style={styles.changeMode}>
                Change
              </Link>
            )}
          </span>
        </div>
        <div style={styles.userContainer}>
          {(() => {
            return greetingName ? (
              <span style={styles.userGreeting}>
                Welcome back, <em>{greetingName}!</em>
              </span>
            ) : (
              ''
            );
          })()}
          <a style={styles.userToggle} onClick={menuOpen ? this.handleMenuClose : this.handleMenuOpen}>
            <img
              style={styles.userPhoto}
              className="header-toggle-drop-picture"
              src={photo || require('!!file-loader!./assets/user-icon.svg')}
              alt="User Avatar"
            />
            <span style={styles.userToggleChevron} />
          </a>
          {menuOpen && (
            <UserMenu
              style={styles.userMenu}
              name={displayName}
              email={email}
              photo={photo}
              logout={() => (location.href = '/auth/logout')}
              disableClick={!isOnboarded}
              {...(this.props as any)}
            />
          )}
        </div>
      </header>
    );
  }

  renderBreadcrumb() {
    // const { audiencesById, location } = this.props;
    // const titles = [];
    // let icon = null;

    return null;

    // for (const route of routes) {
    //   if (route.icon) {
    //     icon = route.icon;
    //   }
    //   if (route.audienceInBreadcrumb) {
    //     if (audiencesById && audiencesById[audienceId]) {
    //       titles.push((audiencesById[audienceId] as ResponseOuApiAudience).definition.name);
    //     } else if (!audienceId) {
    //       titles.push('Master');
    //     }
    //   }
    //   if (route.title) {
    //     titles.push(route.title);
    //   }
    // }

    // return (
    //   <h1 className="view-title">
    //     {icon}
    //     {titles.join(' / ')}
    //   </h1>
    // );
  }
}

// tslint:disable-next-line
class OnboardingScript extends React.Component<
  Pick<Props, 'currentOrg'> & Pick<Props, 'user'> & Pick<Props, 'isFree'>
> {
  private scriptEl;

  handleLoad = () => {
    const { user, currentOrg, isFree } = this.props;
    if (!user || !currentOrg || !window['Appcues']) {
      return;
    }

    window['Appcues'].identify(user.id, {
      // Unique identifier for current user
      name: user.displayName, // Current user's name
      email: user.email, // Current user's email
      created_at: user.createdAt, // Unix timestamp of user signup date
      plan: isFree ? 'Lite' : 'Premium',
      org: currentOrg.id,
      orgName: currentOrg.name,
      orgType: currentOrg.type,
      conntected: currentOrg.connected,
      office365Tenant: currentOrg.remoteTenant,
      orgMode: currentOrg.settings[currentOrg.id].global_mode,
      msGraphAdminAuthenticated: currentOrg.settings[currentOrg.id].auth_providers_microsoftGraph_adminConsent,
      orgOnboardingStep: currentOrg.settings[currentOrg.id].onboarding_step,
      userLocale: user.preferences.locale,

      // Additional user properties.
      // is_trial: false,
      // plan: "enterprise"
    });
  };

  componentDidMount() {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = '//fast.appcues.com/28628.js';
    s.onload = this.handleLoad;
    document.body.appendChild(s);
    this.scriptEl = s;
  }

  componentWillUnmount() {
    document.body.removeChild(this.scriptEl);
  }

  render() {
    return null;
  }
}

export const AppLayout = connector(withRouter(Radium(AppLayoutC)));
