import { combineReducers } from 'redux';

import analytics from './analytics';
import audits from './audits';
import auth from './auth';
import branding from './branding';
import broadcasts from './broadcasts';
import changes from './changes';
import collections from './collections';
import devices from './devices';
import emails from './emails';
import events from './events';
import externalUsers from './externalUsers';
import fieldSchema from './fieldSchema';
import notifications from './notifications';
import orgs from './orgs';
import permissions from './permissions';
import premiumModals from './premiumModals';
import providers from './providers';
import resources from './resources';
import self from './self';
import settings from './settings';
// import suggestions from './suggestions';
import ui from './ui';
import validations from './validations';
import violationUsers from './violationUsers';
import localizations from './localizations';
import relationships from './relationships';

export default combineReducers({
  // Antrea Reducers
  analytics,
  audits,
  auth,
  branding,
  broadcasts,
  changes,
  collections,
  devices,
  emails,
  events,
  externalUsers,
  fieldSchema,
  notifications,
  orgs,
  permissions,
  premiumModals,
  providers,
  resources,
  self,
  settings,
  // suggestions,
  ui,
  validations,
  violationUsers,
  localizations,
  relationships,
});
