import '!style-loader!css-loader!postcss-loader!sass-loader!./assets/styles/main.scss';

import React, { FunctionComponent } from 'react';
import { hot } from 'react-hot-loader/root';
import { Redirect, Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import { Store } from 'redux';

import { AppLayout, MarketingLayout } from './layouts';
import { GlobalState } from './models/client';
import { isAdmin, isAuthed, isApprover } from './redux/modules/auth';
import { LoginSuccess } from './containers';
import { AuthedRoute } from './components';

export interface AppRouterProps {
  store: Store<GlobalState>;
}

const Root: FunctionComponent<AppRouterProps> = ({ store }) => {
  const state = store.getState();
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            const state = store.getState();
            if (!isAuthed(state)) {
              return <Redirect to="/login" />;
            } else if (isAdmin(state)) {
              return <Redirect to="/home" />;
            } else if (isApprover(state)) {
              return <Redirect to="/approve" />;
            }
            return <Redirect to="/self" />;
          }}
        />
        <Route exact path="/loginsuccess" component={LoginSuccess} />
        <Route
          path={[
            '/self',
            '/users/:userId',
            '/login',
            '/validationsuccess',
            '/directory',
            '/relationshipchart',
            '/orgchart',
            '/org-chart',
            '/userslist',
          ]}
          component={MarketingLayout}
        />
        <AuthedRoute isAllowed={isAuthed(state) && (isAdmin(state) || isApprover(state))} component={AppLayout} />
      </Switch>
    </Router>
  );
};

export default hot(Root);
